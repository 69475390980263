import React, {useState, useEffect} from "react";
import { Link } from 'gatsby';
import { Transition } from 'react-transition-group';
//import { TransitionPortal } from "gatsby-plugin-transition-link";
//import AniLink from "gatsby-plugin-transition-link/AniLink";
import Headroom from 'react-headroom';
import "../styles/nav.scss";
import Resume from "../assets/resume.pdf";
import "../styles/hamburgers.scss"

import Instagram from "../assets/instagram.svg";
import Linkedin from "../assets/linkedin.svg";
import Github from "../assets/github.svg";
import Dribbble from "../assets/dribbble.svg";

const duration = 300;

const defaultStyleMenu = {
  transition: `${duration}ms ease-in-out`,
  transitionProperty: "top",
  top: '-100%'
}

const defaultStyleBackground = {
  transition: `${duration}ms ease-in-out`,
  transitionProperty: "opacity",
  opacity: '0'
}

const transitionStylesMenu = {
  entering: {top: '-100%', display: "flex"},
  entered:  {top: "0%" },
  exiting:  {top: '-100%'},
  exited:  {top: '-100%', display: "none"},
};

const transitionStylesBackground = {
  entering: {opacity: 0, display: "flex" },
  entered:  {opacity: 1 },
  exiting:  {opacity: 0 },
  exited:  {opacity: 0, display: "none" },
};

const Nav = props => {
  const [navWhite, setNavWhite] = useState(false);
  const [activeMobile, setActiveMobile] = useState(false);

  useEffect(() => {
    if(props.scrollPoint) {
      window.addEventListener("scroll", handleScroll);
    }

    return function cleanup() {
      window.removeEventListener("scroll", handleScroll)
    };
  });
  
  const handleScroll = () => {
    var elem = document.querySelector(props.scrollPoint);
    var bounding = elem.getBoundingClientRect();
    if (bounding.bottom < 40) {
      if(!navWhite){
        setNavWhite(true);
      }
    } else {
      if(navWhite){
        setNavWhite(false);
      }
    }
  }
  
  const handleClick = () => {
    setActiveMobile(!activeMobile);
  }

  
  return (
      <>
        <Headroom disableInlineStyles={true} pinStart={200}>
          <nav className={navWhite ? "white" : null}>
              <div className="container">
                  <div className="home">
                      <Link to="/" onClick={() => setActiveMobile(false)}>/thevarunkhatri</Link>
                  </div>
                  <ul className="nav-items">
                      <li><a href="https://medium.com/@thevarunkhatri" target="_blank" rel="noreferrer" >/blog</a></li>
                      <li><Link to="/about">/about</Link></li>
                      <li><a href={Resume} target="_blank" rel="noreferrer">/resume</a></li>
                  </ul>
                  <span class={activeMobile ? "hamburger hamburger--emphatic is-active" : "hamburger hamburger--emphatic"} onClick={handleClick}>
                    <span class="hamburger-box">
                      <span class="hamburger-inner"></span>
                    </span>
                  </span>  
              </div>
          </nav>
        </Headroom>
        <Transition in={activeMobile} timeout={activeMobile ? 0 : duration}>
          {state => (
            <div className="mobileNav-menu" style={{
              ...defaultStyleBackground,
              ...transitionStylesBackground[state]
            }}>
              <div className="mobileNav-menuContainer" style={{
              ...defaultStyleMenu,
              ...transitionStylesMenu[state]
            }}>
                <div className="mobileNav-menuLinks container">
                  <a onClick={() => setActiveMobile(false)} href="https://medium.com/@thevarunkhatri" target="_blank" rel="noreferrer" className="mobileNav-startupLogo">Blog</a>
                  <Link onClick={() => setActiveMobile(false)} to="/about" className="mobileNav-startupLogo">About</Link>
                  <a onClick={() => setActiveMobile(false)} href={Resume} target="_blank" rel="noreferrer" className="mobileNav-startupLogo">Resume</a>
                </div>
                <div className="mobileNav-socialMedia container">
                  <a href="https://www.linkedin.com/in/varunmk/" target="_blank" rel="noreferrer"><Linkedin/></a>
                  <a href="https://dribbble.com/thevarunkhatri" target="_blank" rel="noreferrer"><Dribbble/></a>
                  <a href="https://github.com/thevarunkhatri" target="_blank" rel="noreferrer"><Github/></a>
                  <a href="https://www.instagram.com/thevarunkhatri/?hl=en" target="_blank" rel="noreferrer"><Instagram/></a>
                </div>
              </div>
            </div>
          )} 
        </Transition>
      </>
  )
}

export default Nav
